import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

import "stylesheets/application.scss";
import "controllers/index.js"

document.addEventListener("turbo:load", () => {
  BSN.initCallback(document.body)
})

require("chart.js")

const images = require.context('images', true)

Rails.start()
ActiveStorage.start()
